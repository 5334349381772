@import "_generated-font-mixins.scss";
@font-face {
  font-family: "999-icon-font";
  src: url('/static/fonts/999-icon-font.eot?t=1740647511929'); 
  src: url('/static/fonts/999-icon-font.eot?t=1740647511929#iefix') format('embedded-opentype'), 
  url('/static/fonts/999-icon-font.woff?t=1740647511929') format("woff"),
  url('/static/fonts/999-icon-font.ttf?t=1740647511929') format('truetype'), 
  url('/static/fonts/999-icon-font.svg?t=1740647511929#999-icon-font') format('svg'); 
}

[class^="icon-"], [class*=" icon-"] {
  font-family: '999-icon-font' !important;
  color: #000000; 
  font-size: 24px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-ad-block-transfer:before { content: "\ea01"; }
.icon-ad-booster:before { content: "\ea02"; }
.icon-ad-btn:before { content: "\ea03"; }
.icon-ad-colorize:before { content: "\ea04"; }
.icon-ad-edit:before { content: "\ea05"; }
.icon-ad-hide:before { content: "\ea06"; }
.icon-ad-pay:before { content: "\ea07"; }
.icon-ad-republish-free:before { content: "\ea08"; }
.icon-ad-republish:before { content: "\ea09"; }
.icon-ad-show:before { content: "\ea0a"; }
.icon-adversting-header:before { content: "\ea0b"; }
.icon-alarm-arrow:before { content: "\ea0c"; }
.icon-alarm-step:before { content: "\ea0d"; }
.icon-arrow-back:before { content: "\ea0e"; }
.icon-arrow-down:before { content: "\ea0f"; }
.icon-arrow-header:before { content: "\ea10"; }
.icon-arrow-left:before { content: "\ea11"; }
.icon-arrow-navigation-left:before { content: "\ea12"; }
.icon-arrow-navigation-right:before { content: "\ea13"; }
.icon-arrow-right:before { content: "\ea14"; }
.icon-arrow-small-left:before { content: "\ea15"; }
.icon-arrow-small-right:before { content: "\ea16"; }
.icon-arrow-up:before { content: "\ea17"; }
.icon-arrows-left:before { content: "\ea18"; }
.icon-arrows-right:before { content: "\ea19"; }
.icon-aside-btn:before { content: "\ea1a"; }
.icon-attention:before { content: "\ea1b"; }
.icon-auto-refresh:before { content: "\ea1c"; }
.icon-bargaining:before { content: "\ea1d"; }
.icon-bars:before { content: "\ea1e"; }
.icon-basic:before { content: "\ea1f"; }
.icon-bcrumb-separator:before { content: "\ea20"; }
.icon-blog:before { content: "\ea21"; }
.icon-booking-price:before { content: "\ea22"; }
.icon-booking-status:before { content: "\ea23"; }
.icon-booking-user:before { content: "\ea24"; }
.icon-booking:before { content: "\ea25"; }
.icon-bookings:before { content: "\ea26"; }
.icon-bookmark-header:before { content: "\ea27"; }
.icon-booster:before { content: "\ea28"; }
.icon-business-header:before { content: "\ea29"; }
.icon-business:before { content: "\ea2a"; }
.icon-calendar-icon:before { content: "\ea2b"; }
.icon-calendar-today:before { content: "\ea2c"; }
.icon-calendar:before { content: "\ea2d"; }
.icon-camera-2:before { content: "\ea2e"; }
.icon-camera-plus:before { content: "\ea2f"; }
.icon-camera:before { content: "\ea30"; }
.icon-cancel:before { content: "\ea31"; }
.icon-chat:before { content: "\ea32"; }
.icon-check-step:before { content: "\ea33"; }
.icon-check-thin:before { content: "\ea34"; }
.icon-check:before { content: "\ea35"; }
.icon-checkmark:before { content: "\ea36"; }
.icon-chevron-down:before { content: "\ea37"; }
.icon-choose-data:before { content: "\ea38"; }
.icon-choose-period:before { content: "\ea39"; }
.icon-cigarrete:before { content: "\ea3a"; }
.icon-clock-fill:before { content: "\ea3b"; }
.icon-clock:before { content: "\ea3c"; }
.icon-close-booking:before { content: "\ea3d"; }
.icon-close:before { content: "\ea3e"; }
.icon-confirm:before { content: "\ea3f"; }
.icon-contact-delete-user:before { content: "\ea40"; }
.icon-contacts:before { content: "\ea41"; }
.icon-copy-blue:before { content: "\ea42"; }
.icon-copy:before { content: "\ea43"; }
.icon-date-picker:before { content: "\ea44"; }
.icon-delete-number:before { content: "\ea45"; }
.icon-delivery-truck-1:before { content: "\ea46"; }
.icon-delivery-truck2:before { content: "\ea47"; }
.icon-dots-vertical:before { content: "\ea48"; }
.icon-down:before { content: "\ea49"; }
.icon-download:before { content: "\ea4a"; }
.icon-draft-add:before { content: "\ea4b"; }
.icon-energy:before { content: "\ea4c"; }
.icon-engine:before { content: "\ea4d"; }
.icon-error:before { content: "\ea4e"; }
.icon-essential:before { content: "\ea4f"; }
.icon-estate:before { content: "\ea50"; }
.icon-eye-fill:before { content: "\ea51"; }
.icon-eye:before { content: "\ea52"; }
.icon-facebook:before { content: "\ea53"; }
.icon-fill-heart:before { content: "\ea54"; }
.icon-filter-btn:before { content: "\ea55"; }
.icon-frame-17:before { content: "\ea56"; }
.icon-frame:before { content: "\ea57"; }
.icon-heart:before { content: "\ea58"; }
.icon-highlight:before { content: "\ea59"; }
.icon-history-actions:before { content: "\ea5a"; }
.icon-history-header:before { content: "\ea5b"; }
.icon-history-mob:before { content: "\ea5c"; }
.icon-history-no-items:before { content: "\ea5d"; }
.icon-history-two:before { content: "\ea5e"; }
.icon-history-view:before { content: "\ea5f"; }
.icon-history:before { content: "\ea60"; }
.icon-home-2:before { content: "\ea61"; }
.icon-home-3:before { content: "\ea62"; }
.icon-home:before { content: "\ea63"; }
.icon-icon-cog:before { content: "\ea64"; }
.icon-icon-minus:before { content: "\ea65"; }
.icon-icon-plus:before { content: "\ea66"; }
.icon-inactive-eye:before { content: "\ea67"; }
.icon-info-tip:before { content: "\ea68"; }
.icon-info:before { content: "\ea69"; }
.icon-instagram:before { content: "\ea6a"; }
.icon-key:before { content: "\ea6b"; }
.icon-label:before { content: "\ea6c"; }
.icon-lang:before { content: "\ea6d"; }
.icon-like:before { content: "\ea6e"; }
.icon-list:before { content: "\ea6f"; }
.icon-lite-package:before { content: "\ea70"; }
.icon-log-in:before { content: "\ea71"; }
.icon-log-out-header:before { content: "\ea72"; }
.icon-log-out:before { content: "\ea73"; }
.icon-mail-classic:before { content: "\ea74"; }
.icon-mail-two:before { content: "\ea75"; }
.icon-mail:before { content: "\ea76"; }
.icon-map-pin:before { content: "\ea77"; }
.icon-market-fill:before { content: "\ea78"; }
.icon-market:before { content: "\ea79"; }
.icon-marketplace:before { content: "\ea7a"; }
.icon-mastercard:before { content: "\ea7b"; }
.icon-message-booking:before { content: "\ea7c"; }
.icon-message:before { content: "\ea7d"; }
.icon-messages-header:before { content: "\ea7e"; }
.icon-minus-sign:before { content: "\ea7f"; }
.icon-minus:before { content: "\ea80"; }
.icon-mobile:before { content: "\ea81"; }
.icon-money:before { content: "\ea82"; }
.icon-moon:before { content: "\ea83"; }
.icon-more:before { content: "\ea84"; }
.icon-my-ads:before { content: "\ea85"; }
.icon-next:before { content: "\ea86"; }
.icon-notification-nav:before { content: "\ea87"; }
.icon-notification-two:before { content: "\ea88"; }
.icon-notifications-header:before { content: "\ea89"; }
.icon-notifications:before { content: "\ea8a"; }
.icon-orders-header:before { content: "\ea8b"; }
.icon-package:before { content: "\ea8c"; }
.icon-paperclip:before { content: "\ea8d"; }
.icon-payment:before { content: "\ea8e"; }
.icon-payments:before { content: "\ea8f"; }
.icon-pencil-edit:before { content: "\ea90"; }
.icon-pencil:before { content: "\ea91"; }
.icon-phone-call:before { content: "\ea92"; }
.icon-phone-classic:before { content: "\ea93"; }
.icon-phone-contact:before { content: "\ea94"; }
.icon-phone:before { content: "\ea95"; }
.icon-photo-camera:before { content: "\ea96"; }
.icon-photo-plus:before { content: "\ea97"; }
.icon-pin:before { content: "\ea98"; }
.icon-play-icon:before { content: "\ea99"; }
.icon-plus-bg:before { content: "\ea9a"; }
.icon-plus-package:before { content: "\ea9b"; }
.icon-plus-sign:before { content: "\ea9c"; }
.icon-plus:before { content: "\ea9d"; }
.icon-post-ad:before { content: "\ea9e"; }
.icon-previous:before { content: "\ea9f"; }
.icon-print-ad:before { content: "\eaa0"; }
.icon-profile-header:before { content: "\eaa1"; }
.icon-profile:before { content: "\eaa2"; }
.icon-question:before { content: "\eaa3"; }
.icon-refresh:before { content: "\eaa4"; }
.icon-registration:before { content: "\eaa5"; }
.icon-rejected-step:before { content: "\eaa6"; }
.icon-remove-account-request:before { content: "\eaa7"; }
.icon-remove:before { content: "\eaa8"; }
.icon-rent-calendar:before { content: "\eaa9"; }
.icon-returned:before { content: "\eaaa"; }
.icon-review-star:before { content: "\eaab"; }
.icon-search-btn:before { content: "\eaac"; }
.icon-search-close:before { content: "\eaad"; }
.icon-search-link:before { content: "\eaae"; }
.icon-search-out:before { content: "\eaaf"; }
.icon-search:before { content: "\eab0"; }
.icon-send:before { content: "\eab1"; }
.icon-settings-two:before { content: "\eab2"; }
.icon-settings:before { content: "\eab3"; }
.icon-share-facebook:before { content: "\eab4"; }
.icon-share:before { content: "\eab5"; }
.icon-shift:before { content: "\eab6"; }
.icon-star-fill:before { content: "\eab7"; }
.icon-star:before { content: "\eab8"; }
.icon-statistics-two:before { content: "\eab9"; }
.icon-statistics:before { content: "\eaba"; }
.icon-subscribe-filters:before { content: "\eabb"; }
.icon-subscription-remove:before { content: "\eabc"; }
.icon-subscriptions:before { content: "\eabd"; }
.icon-success:before { content: "\eabe"; }
.icon-symlink:before { content: "\eabf"; }
.icon-throughout:before { content: "\eac0"; }
.icon-tiktok:before { content: "\eac1"; }
.icon-time:before { content: "\eac2"; }
.icon-trash-can:before { content: "\eac3"; }
.icon-trash-item:before { content: "\eac4"; }
.icon-trash:before { content: "\eac5"; }
.icon-triangle:before { content: "\eac6"; }
.icon-turbo-package:before { content: "\eac7"; }
.icon-union:before { content: "\eac8"; }
.icon-up:before { content: "\eac9"; }
.icon-verified-user:before { content: "\eaca"; }
.icon-video:before { content: "\eacb"; }
.icon-views:before { content: "\eacc"; }
.icon-vimeo-icon:before { content: "\eacd"; }
.icon-visa:before { content: "\eace"; }
.icon-vk:before { content: "\eacf"; }
.icon-wallet-header:before { content: "\ead0"; }
.icon-wallet:before { content: "\ead1"; }
.icon-warning:before { content: "\ead2"; }
.icon-wide-heart:before { content: "\ead3"; }
.icon-widecard:before { content: "\ead4"; }
.icon-youtube-icon:before { content: "\ead5"; }

