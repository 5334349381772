/* stylelint-disable selector-class-pattern */
.rbc-off-range-bg {
  background: rgb(240 240 240 / 100%) !important;
}

.rbc-header {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 44px;

  border-left: 1px solid transparent !important;
}

.rbc-header > span {
  font-size: 14px;
  font-weight: 400;
  color: rgb(85 85 85 / 100%);
  text-transform: capitalize;
}

.rbc-month-view {
  border: 1px solid transparent !important;
}

.rbc-month-row {
  overflow: visible !important;
  border-right: 1px solid rgb(227 227 227 / 100%) !important;
  border-left: 1px solid rgb(227 227 227 / 100%) !important;
}

.rbc-month-row:last-child {
  border-bottom: 1px solid rgb(227 227 227 / 100%) !important;
}

.rbc-current > button {
  color: white;
  background-color: rgb(0 92 195 / 100%);
  border-radius: 90px;
}

.rbc-date-cell > button {
  z-index: 5;
  padding: 5px;
  font:
    14px Arial,
    sans-serif;
}

.rbc-date-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}

.rbc-selected-cell {
  background: rgb(0 92 195 / 100%);
  background-color: aqua;
}

.rbc-new-event {
  position: relative;
  color: white;
  background: rgb(0 92 195 / 100%) !important;
}

.rbc-row-content {
  pointer-events: none;
  height: 100%;
}

.rbc-row-segment {
  pointer-events: auto;
}

.scroll-top {
  display: none !important;
}

.rbc-row-bg {
  right: 0 !important;
}
