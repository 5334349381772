@import "_generated-font-mixins.scss";
/* stylelint-disable selector-class-pattern */
@import './normalize.css';
@import './999-icon-font.css';
@import './category-icons-font.css';
@import './datePicker';
@import './bigCalendar';

:root,
body,
html {
  scroll-behavior: smooth;
  height: 100%;
  font-family: Arial, sans-serif;
}

body {
  @media (width <= 640px) {
    overflow: hidden auto;
    width: 100%;
  }

  display: flex;
  flex-direction: column;
}

.apollo__sandbox {
  height: 100%;
}

// fix slick-slider margin between cards
.slick-slider {
  @media screen and (width <= 900px) {
    margin-right: 0;
  }

  margin-right: -12px;

  & .slick-dots {
    position: absolute;
    bottom: 12px;

    display: block;

    width: 100%;
    margin: 0;
    padding: 0;

    text-align: center;
    list-style: none;

    & li {
      cursor: pointer;

      position: relative;

      display: inline-block;

      width: auto;
      height: 20px;
      margin: 0;
      padding: 0;

      & button {
        cursor: pointer;
        display: block;
        width: 17px;

        &::before {
          font-size: 9px;
          color: rgb(196 192 192 / 60%);
          opacity: 1;
        }
      }

      &.slick-active {
        & button::before {
          color: #fff;
        }
      }
    }
  }
}

hr {
  height: 1px;
  margin: 30px 0 0;
  background-color: #ddd;
  border: none;

  &.hr-mobile {
    margin: 24px 0 0;
  }
}

.hidden {
  display: none;
}

// pages content service styles

.pages__wrapper {
  max-width: 1000px;
  margin: 0 auto;
}

.pages__page a {
  color: #005cc3;
  text-decoration: none;
}

.pages__page__title {
  font-size: 36px;
  font-weight: bold;
  color: #000;
}

.pages__page__body {
  margin-top: 35px;
  font-size: 18px;
  line-height: 1.6;
  color: #000;
}

.pages__page__contents {
  margin-top: 40px;
  font-size: 14px;
}

.pages__page__contents__list {
  list-style-type: none;
}

.pages__page__contents__list__item {
  margin-bottom: 7px;
}

.pages__page__children__child {
  margin-top: 35px;
}

.pages__page__children__child__anchor {
  position: relative;
  top: -50px;
  display: block;
  visibility: hidden;
}

.pages__page__children__child__title {
  font-size: 30px;
  font-weight: bold;
  color: #35404d;
}

.pages__page__children__child__body {
  margin-top: 20px;
  font-size: 18px;
  line-height: 1.6;
  color: #000;
}

// end of pages content service styles

.switcher__shadowbox__bottom {
  margin-bottom: 8px;
  border-bottom: 1px solid #e6e6e6;
  box-shadow: 0 6px 6px -2px rgb(0 0 0 / 15%);
}

.switcher__shadowbox__top {
  margin-top: 8px;
  border-bottom: 1px solid #e6e6e6;
  box-shadow: 0 -2px 6px 0 rgb(0 0 0 / 12%);
}

.slick-list {
  max-height: 267px;
}

@media print {
  .no-print {
    display: none !important;
  }
}

// header sidebar

.sidebar__wrapper {
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  padding-bottom: 50px;

  transition: left 0.2s ease;

  &.stop__scroll {
    overflow: hidden;
  }
}

.show__sidebar {
  position: fixed;
  left: 265px;

  & .switcher__item__subcribe__custom {
    position: fixed;
    z-index: 999;
    bottom: 54px;
    left: 265px;

    width: 100%;

    transition: left 0.2s ease;
  }
}

.hide__sidebar {
  position: relative;
  right: 265px;
  overflow: hidden;
  overflow-y: auto;

  & .switcher__item__subcribe__custom {
    position: fixed;
    z-index: 999;
    bottom: 54px;
    left: 0;

    width: 100%;

    transition: left 0.2s ease;
  }
}

.age__control {
  position: relative;
  overflow: hidden;

  &::before {
    content: '18+';

    position: absolute;
    z-index: 11;
    top: 5px;
    left: 5px;

    width: 29px;
    height: 22px;

    font-size: 12px;
    line-height: 22px;
    color: #fff;
    text-align: center;

    background: rgb(0 0 0 / 50%);
    border-radius: 2px;
  }

  &::after {
    content: '';

    position: absolute;
    z-index: 10;
    inset: 0;

    background: rgb(217 217 217 / 25%);
    backdrop-filter: blur(23.5px);
  }
}

.fancybox__container {
  z-index: 19999 !important;
}

.f-thumbs__slide {
  &.for-image {
    opacity: 0.5 !important;

    &.is-nav-selected {
      opacity: 1 !important;
    }
  }
}

.mapboxgl-popup.popup-mapgl {
  transform: translate(0, 0) translate(20px, 20px) !important;
}

.mapboxgl-popup-tip {
  display: none !important;
}
