/* stylelint-disable selector-class-pattern */

.react-datepicker__month-container:first-of-type .react-datepicker__day--outside-month {
  visibility: hidden;
}

.react-datepicker__month-container:last-of-type .react-datepicker__day--outside-month {
  visibility: hidden;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
}

.react-datepicker__header {
  background-color: #fff !important;
  border-bottom: none !important;
}

.react-datepicker__month-container {
  padding: 16px;
}

.react-datepicker__month {
  margin: 0 !important;
  color: #1d2429 !important;
}

.react-datepicker {
  font-size: 12px !important;
  line-height: 20px;
  color: #666;
  letter-spacing: 0.2px;

  border: 1px solid rgb(235 235 235) !important;
  border-radius: 0 !important;
}

.react-datepicker__week {
  display: flex;
}

.react-datepicker__day-name {
  color: rgb(132 144 149) !important;
  text-transform: capitalize !important;
}

.react-datepicker__day--keyboard-selected {
  background: none !important;
  border: none;
}

.react-datepicker__day--today {
  background: rgb(252 252 252 / 100%) !important;
  border: 1px solid rgb(227 227 227 / 100%) !important;
  border-radius: 4px !important;
}

.react-datepicker__day--today:focus,
.react-datepicker__day--today:focus-visible {
  background: rgb(252 252 252 / 100%) !important;
  border: 1px solid rgb(227 227 227 / 100%) !important;
  border-radius: 4px !important;
  outline: none !important;
  box-shadow: none !important;
}

.react-datepicker__day,
.react-datepicker__day-name {
  display: flex !important;
  align-items: center;
  justify-content: center;

  width: 40px !important;
  height: 40px;
  margin: 0 !important;

  line-height: 20px;
  vertical-align: middle;
}

.react-datepicker-popper {
  top: 0 !important;
  bottom: 100% !important;
  left: -150px !important;
}

.react-datepicker__day--selected {
  font-weight: 600;
  background-color: rgb(0 92 195 / 100%) !important;
  border-radius: 4px;
}

.react-datepicker__day--selected:focus-within {
  font-weight: 600;
  background-color: rgb(0 92 195 / 100%) !important;
  border-radius: 4px;
}

.react-datepicker__day-names {
  display: flex;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__input-container {
  display: none !important;
}

.react-datepicker__day--disabled {
  pointer-events: none;
  color: #d3d3d3;
  text-decoration: line-through;
}

.react-datepicker__day--in-range {
  color: #1d2429 !important;
  background-color: rgb(237 244 252 / 100%) !important;
  border-radius: 0 !important;
}

.react-datepicker__day--range-start {
  color: #fff !important;

  background-color: rgb(0 92 195 / 100%) !important;
  border-width: 0;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.react-datepicker__day--range-end {
  color: #fff !important;

  background-color: rgb(0 92 195 / 100%) !important;
  border-width: 0;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

@media (width <= 640px) {
  .react-datepicker {
    width: 100% !important;
    max-width: 100%;
    font-size: 12px !important;
  }

  .react-datepicker__day {
    width: 100% !important;
    height: 50px !important;
  }

  .react-datepicker__day-names {
    justify-content: space-between !important;
  }

  .react-datepicker__month-container {
    float: none !important;
  }

  .react-datepicker__week {
    justify-content: space-between !important;
  }
}
