@import "_generated-font-mixins.scss";
@font-face {
  font-family: "category-icons-font";
  src: url('category-icons-font.eot?t=1742375618192'); 
  src: url('category-icons-font.eot?t=1742375618192#iefix') format('embedded-opentype'), 
  url("category-icons-font.woff?t=1742375618192") format("woff"),
  url('category-icons-font.ttf?t=1742375618192') format('truetype'), 
  url('category-icons-font.svg?t=1742375618192#category-icons-font') format('svg'); 
}

[class^="category-icon-"], [class*=" category-icon-"] {
  font-family: 'category-icons-font' !important;
  color: #000000; 
  font-size: 24px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.category-icon-2:before { content: "\ea0a"; }
.category-icon-38:before { content: "\ea0e"; }
.category-icon-44:before { content: "\ea0f"; }
.category-icon-45:before { content: "\ea10"; }
.category-icon-226:before { content: "\ea0b"; }
.category-icon-269:before { content: "\ea0c"; }
.category-icon-270:before { content: "\ea0d"; }
.category-icon-658:before { content: "\ea15"; }
.category-icon-693:before { content: "\ea40"; }
.category-icon-753:before { content: "\ea47"; }
.category-icon-1155:before { content: "\ea01"; }
.category-icon-1170:before { content: "\ea02"; }
.category-icon-1182:before { content: "\ea03"; }
.category-icon-1187:before { content: "\ea04"; }
.category-icon-1195:before { content: "\ea05"; }
.category-icon-1213:before { content: "\ea06"; }
.category-icon-1223:before { content: "\ea07"; }
.category-icon-1237:before { content: "\ea08"; }
.category-icon-1412:before { content: "\ea09"; }
.category-icon-6026:before { content: "\ea11"; }
.category-icon-6146:before { content: "\ea12"; }
.category-icon-6147:before { content: "\ea13"; }
.category-icon-6341:before { content: "\ea14"; }
.category-icon-6643:before { content: "\ea16"; }
.category-icon-6776:before { content: "\ea17"; }
.category-icon-6777:before { content: "\ea18"; }
.category-icon-6778:before { content: "\ea19"; }
.category-icon-6779:before { content: "\ea1a"; }
.category-icon-6780:before { content: "\ea1b"; }
.category-icon-6781:before { content: "\ea1c"; }
.category-icon-6782:before { content: "\ea1d"; }
.category-icon-6783:before { content: "\ea1e"; }
.category-icon-6784:before { content: "\ea1f"; }
.category-icon-6785:before { content: "\ea20"; }
.category-icon-6786:before { content: "\ea21"; }
.category-icon-6787:before { content: "\ea22"; }
.category-icon-6788:before { content: "\ea23"; }
.category-icon-6789:before { content: "\ea24"; }
.category-icon-6790:before { content: "\ea25"; }
.category-icon-6845:before { content: "\ea26"; }
.category-icon-6859:before { content: "\ea27"; }
.category-icon-6860:before { content: "\ea28"; }
.category-icon-6861:before { content: "\ea29"; }
.category-icon-6862:before { content: "\ea2a"; }
.category-icon-6863:before { content: "\ea2b"; }
.category-icon-6865:before { content: "\ea2c"; }
.category-icon-6866:before { content: "\ea2d"; }
.category-icon-6867:before { content: "\ea2e"; }
.category-icon-6869:before { content: "\ea2f"; }
.category-icon-6870:before { content: "\ea30"; }
.category-icon-6871:before { content: "\ea31"; }
.category-icon-6872:before { content: "\ea32"; }
.category-icon-6874:before { content: "\ea33"; }
.category-icon-6875:before { content: "\ea34"; }
.category-icon-6876:before { content: "\ea35"; }
.category-icon-6877:before { content: "\ea36"; }
.category-icon-6878:before { content: "\ea37"; }
.category-icon-6879:before { content: "\ea38"; }
.category-icon-6880:before { content: "\ea39"; }
.category-icon-6881:before { content: "\ea3a"; }
.category-icon-6882:before { content: "\ea3b"; }
.category-icon-6885:before { content: "\ea3c"; }
.category-icon-6888:before { content: "\ea3d"; }
.category-icon-6909:before { content: "\ea3e"; }
.category-icon-6920:before { content: "\ea3f"; }
.category-icon-7133:before { content: "\ea41"; }
.category-icon-7247:before { content: "\ea42"; }
.category-icon-7477:before { content: "\ea43"; }
.category-icon-7483:before { content: "\ea44"; }
.category-icon-7495:before { content: "\ea45"; }
.category-icon-7522:before { content: "\ea46"; }
.category-icon-7591:before { content: "\ea48"; }
.category-icon-7633:before { content: "\ea49"; }

